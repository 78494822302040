import React from "react"
import { chakra, Box, Flex, Text } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import {
  Hero,
  SectionHeading,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Container,
  Layout,
  Section,
  Main,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  FlyingWVBg,
  HighlightedProvider,
  Seo,
} from "../../../components/elements"

const ChakraImg = chakra(GatsbyImage)
const ChakraBG = chakra(FlyingWVBg)

const Provider = ({ data }) => {
  const page = data.datoCmsCancerProvider

  return (
    <Layout>
      <Seo
        title={page.seo.title ? page.seo.title : page.fullName}
        description={page.seo.description ? page.seo.description : " "}
        image={page.seo.image ? page.seo.image.url : " "}
      />

      <Hero
        overflow="hidden"
        maxHeight={280}
        textAlign="center"
        bgGradient="linear(to-t, primary, primaryHover)"
        sx={{ color: "primaryHover" }}
      >
        <ChakraBG objectFit="cover" opacity={0.75} />
      </Hero>

      <Box mt={[-24, -24, -28]}>
        <Container>
          <Box width="full" mx="auto">
            <Box mx="auto" display="block" textAlign="center" width="full">
              <ChakraImg
                borderStyle="solid"
                borderWidth={[4, 5, 6]}
                borderRadius="full"
                boxShadow="lg"
                image={page.portrait.gatsbyImageData}
                objectFit="cover"
                objectPosition="50% 50%"
                borderColor="white"
                height={["140px", "180px", "220px"]}
                mx="auto"
                width={["140px", "180px", "220px"]}
              />
            </Box>
          </Box>

          <Box textAlign="center" mt={6}>
            <Box as="h2" mb={2}>
              {page.fullName}
            </Box>
            <Text mb={0}>{page.roleTitle}</Text>
          </Box>
        </Container>
      </Box>

      <Main>
        {/* Bio */}
        <Section id="bio">
          <Container>
            {/* Bio */}
            <Flex flexWrap="wrap" justifyContent="center">
              <Box width={["full", 4 / 5, 2 / 3]}>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: page.contentNode.childMarkdownRemark.html,
                  }}
                />
              </Box>
            </Flex>
          </Container>
        </Section>

        {/* Providers */}
        <Section id="highlighted-providers" bg="lightgray" data-datocms-noindex>
          <Container>
            <Box width="full">
              <SectionHeading textAlign="center">
                Highlighted Providers
              </SectionHeading>
            </Box>
            <HighlightedProvider />
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default Provider

export const query = graphql`
  query providerPageQuery($slug: String!) {
    datoCmsCancerProvider(slug: { eq: $slug }) {
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      fullName
      id
      roleTitle
      slug
      meta {
        status
      }
      portrait {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { faceindex: 1, fit: "facearea", facepad: 4 }
        )
      }
      seo {
        description
        title
        image {
          url
        }
      }
    }
  }
`
